import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LicenseNumbersMeta } from "../data/seo-meta"

const LicenseNumbersPage = () => {
  return (
    <Layout>
      <SEO
        description={LicenseNumbersMeta.description}
        path={LicenseNumbersMeta.path}
        title={LicenseNumbersMeta.title}
      />
      <div className="container py-20 md:py-24">
        <h1 className="h1 md:mb-20">Slice License Numbers</h1>
        <h2 className="p1 font-semibold md:mb-20">US State Numbers</h2>
        <p className="p2">
          Alabama 763468
          <br />
          Alaska 100132295
          <br />
          Arizona 1149031
          <br />
          Arkansas 100154513
          <br />
          California 0K73569, 0K73569-SL
          <br />
          Colorado 513812
          <br />
          Connecticut 2512317, 002517911-SL
          <br />
          Delaware 1409183
          <br />
          District of Columbia 3093312
          <br />
          Florida L098806
          <br />
          Georgia 190976
          <br />
          Hawaii 440968, 442955
          <br />
          Idaho 579992
          <br />
          Illinois 100755596
          <br />
          Indiana 3163894, 3174850
          <br />
          Iowa 1002268183
          <br />
          Kansas 475538041-0
          <br />
          Kentucky DOI-917865
          <br />
          Louisiana 696102
          <br />
          Massachusetts 2008217
          <br />
          Maine AGN269491
          <br />
          Maryland 3000009763
          <br />
          Michigan 109844
          <br />
          Minnesota 40486534
          <br />
          Mississippi 15029435
          <br />
          Missouri 8381273
          <br />
          Montana 100155732
          <br />
          Nebraska 100252128
          <br />
          Nevada 3170106, 3174879
          <br />
          New Hampshire 2347261
          <br />
          New Jersey 1613024
          <br />
          New Mexico 100014281, 100014529
          <br />
          New York EX-1416522, BR-1416522, LA-1416522, PC-1416522
          <br />
          North Carolina 1000481635
          <br />
          North Dakota 2000157713
          <br />
          Ohio 1122661
          <br />
          Oklahoma 100261158
          <br />
          Oregon 100289738
          <br />
          Pennsylvania 770262, 766649
          <br />
          Rhode Island 2334169
          <br />
          South Carolina 209071
          <br />
          South Dakota 10018973
          <br />
          Tennessee 2334430
          <br />
          Texas 2083010, 2076558
          <br />
          Utah 575546
          <br />
          Vermont 3158307
          <br />
          Virginia 140042
          <br />
          Washington 911760
          <br />
          West Virginia 100221768
          <br />
          Wisconsin 100208585
          <br />
          Wyoming 314100
        </p>
      </div>
    </Layout>
  )
}

export default LicenseNumbersPage
